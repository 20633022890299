<template>
  <div class="relative" v-if="!withText">
    <div
      class="app-tabs-large sticky-SitesSettingsHeader bg-white z-40"
      :class="
        responsive
          ? 'border-none lg:border-b lg:border-gray-200'
          : 'border-b border-gray-200'
      "
      v-if="!secondary"
    >
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        :class="[{ active: activeTabIndex === index }, tabColour]"
        class="app-tabs-large__tab"
        @click="changeTab(index)"
      >
        {{ tab.title }}
      </div>
    </div>

    <div
      class="app-tabs-small sticky-SitesSettingsHeader bg-white border-b border-gray-200 z-40"
      v-else
    >
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{ active: activeTabIndex === index }"
        class="app-tabs-small__tab"
        @click="changeTab(index)"
      >
        {{ tab.title }}
      </div>
    </div>

    <div
      class="border-gray-200"
      :class="`${secondary ? 'py-4' : 'p-4'} ${explore ? '!px-0 !pt-6' : ''} ${
        settings ? 'mt-6' : ''
      }`"
    >
      <slot v-if="tabs[activeTabIndex]" :name="tabs[activeTabIndex].id"></slot>
    </div>
  </div>
  <div class="relative" v-else>
    <div class="flex justify-between items-center">
      <div>
        <slot name="text"></slot>
      </div>
      <div>
        <div
          class="app-tabs-large sticky-SitesSettingsHeader bg-white z-40"
          :class="
            responsive
              ? 'border-none lg:border-b lg:border-gray-200'
              : 'border-b border-gray-200'
          "
          v-if="!secondary"
        >
          <div
            v-for="(tab, index) in tabs"
            :key="index"
            :class="[
              { active: activeTabIndex === index },
              tabColour,
              { 'hidden lg:block': responsive },
            ]"
            class="app-tabs-large__tab"
            @click="changeTab(index)"
          >
            {{ tab.title }}
          </div>
          <BaseSelect
            class="block lg:hidden"
            v-if="responsive"
            :name="'selectTab'"
            :id="'selectTab'"
            :options="tabsOptions"
            @selectionChange="changeTab($event)"
            :value="activeTabIndex"
          ></BaseSelect>
        </div>
        <div
          class="app-tabs-small sticky-SitesSettingsHeader bg-white border-b border-gray-200 z-40"
          v-else
        >
          <div
            v-for="(tab, index) in tabs"
            :key="index"
            :class="{ active: activeTabIndex === index }"
            class="app-tabs-small__tab"
            @click="changeTab(index)"
          >
            {{ tab.title }}
          </div>
        </div>
      </div>
    </div>
    <div
      :class="`${secondary ? 'py-4' : 'p-4'} ${explore ? '!px-0 !pt-6' : ''} ${
        settings ? 'mt-6' : ''
      }`"
    >
      <slot v-if="tabs[activeTabIndex]" :name="tabs[activeTabIndex].id"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    tabColour: {
      type: String,
      default: 'primary',
    },
    fullwidth: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    settings: {
      type: Boolean,
      default: false,
    },
    explore: {
      type: Boolean,
      default: true,
    },
    initTabIndex: {
      type: Number,
      default: 0,
    },
    withText: {
      type: Boolean,
      default: false,
    },
    responsive: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    initTabIndex() {
      this.activeTabIndex = this.initTabIndex
    },
    tabs: {
      handler: function (newValue) {
        if (newValue.length > 0) {
          this.tabsOptions = newValue.map((obj, index) => {
            return { name: obj.title, value: index }
          })
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      activeTabIndex: 0,
      tabsOptions: [],
    }
  },
  mounted() {
    this.activeTabIndex = this.initTabIndex
  },
  methods: {
    changeTab(index) {
      this.$emit('tabChange', index)
      this.activeTabIndex = index
    },
  },
}
</script>
